exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-carrinho-js": () => import("./../../../src/pages/carrinho.js" /* webpackChunkName: "component---src-pages-carrinho-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pesquisar-js": () => import("./../../../src/pages/pesquisar.js" /* webpackChunkName: "component---src-pages-pesquisar-js" */),
  "component---src-pages-produto-[produto-slug]-js": () => import("./../../../src/pages/produto/[produto-slug].js" /* webpackChunkName: "component---src-pages-produto-[produto-slug]-js" */),
  "component---src-pages-recuperar-senha-js": () => import("./../../../src/pages/recuperar-senha.js" /* webpackChunkName: "component---src-pages-recuperar-senha-js" */),
  "component---src-pages-registrar-js": () => import("./../../../src/pages/registrar.js" /* webpackChunkName: "component---src-pages-registrar-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

